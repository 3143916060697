/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import styled from "@emotion/styled"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Container>
      <HeaderContainer>
        {/*
     <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
  */}
      </HeaderContainer>

      <div
        style={{
          margin: `0 auto`,
          maxWidth: "90%",
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <MainContainer>
          <main>{children}</main>
        </MainContainer>
      </div>
      <FooterContainer>
        <footer className="z-2 absolute left-0 bottom-0 w-full pb-[15px] ">
          <div className="w-full flex justify-center gap-10">
            <Link to="https://github.com/ecadlabs/policies/blob/main/terms-of-service.md" target="_blank">
              <span className="font-[16px] hover:text-orange">
                Terms of Service
              </span>
            </Link>
            <Link to="https://github.com/ecadlabs/policies/blob/main/privacy-policy.md" target="_blank">
              <span className="font-[16px]  hover:text-orange ">
                Privacy Policy
              </span>
            </Link>
          </div>
        </footer>
      </FooterContainer>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const HeaderContainer = styled.div`
  width: 100%;
  height: 175px;
  -webkit-clip-path: polygon(0 0, 100% 56%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 56%, 100% 100%, 0% 100%);
  background: linear-gradient(90deg, #d8944c 1.21%, #c66072 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
`
const FooterContainer = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 125px;
  -webkit-clip-path: polygon(0 0, 100% 56%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 56%, 100% 100%, 0% 100%);
  background: linear-gradient(90deg, #d8944c 1.21%, #c66072 100%);
`
const MainContainer = styled.div`
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  main {
    height: 100%;
  }
`
const Container = styled.div`
  position: relative;
  z-index: 0;
  min-height: 100vh;
  height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
`
